import mixpanel from 'mixpanel-browser';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from '@wilson/wilsonng';
import { finalize, Observable, tap } from 'rxjs';
import { SchoolReportsService } from 'src/app/services/school-reports.service';
import { MixpanelService } from 'src/app/services/mixpanel.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from '@angular/router';
import { Wilson } from 'src/def/Wilson';
import GetSchoolReportResponse = Wilson.GetSchoolReportResponse;
import School = Wilson.School;
import Teacher = Wilson.Teacher;
import TeacherReportByUnit = Wilson.TeacherReportByUnit;

@Component({
  templateUrl: './school-reports.component.html',
})
export class SchoolReportsComponent implements OnInit, OnDestroy {
  private lastSchoolKey: string = 'lastSchoolId';

  programs: LevelOption[] = [
    { label: 'Fundations Level K', programId: 2 },
    { label: 'Fundations Level 1', programId: 3 },
    { label: 'Fundations Level 2', programId: 4 },
    { label: 'Fundations Level 3', programId: 5 },
  ];

  districtName: string;
  schools: School[];
  school: School;
  program: LevelOption = this.programs[0];
  unitIndex: number;
  selectedTeacherId: string;

  schoolReport$: Observable<GetSchoolReportResponse>;
  teachersByUnit$: Observable<Teacher[]>;
  teacherUnitReport$: Observable<TeacherReportByUnit>;

  chartOptions = {
    animation: false,
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
  };

  constructor(
    private userService: UserService,
    private schoolReportsService: SchoolReportsService,
    private mixpanelService: MixpanelService,
    private spinnerService: SpinnerService,
    private router: Router
  ) {
    this.districtName = this.userService.user['districtName'];
  }

  ngOnInit(): void {
    this.spinnerService.show();

    this.schoolReportsService
      .getSchools()
      .pipe(
        tap((schools) => (this.schools = schools)),
        tap((_) => this.getInitialSchoolReport()),
        finalize(() => this.spinnerService.hide())
      )
      .subscribe();

    mixpanel.track('Go To School Reports');
  }

  ngOnDestroy() {
    if (!this.school) return;
    localStorage.setItem(this.lastSchoolKey, this.school.id);
  }

  getInitialSchoolReport(): void {
    this.school =
      this.schools.find(
        (s) => s.id == localStorage.getItem(this.lastSchoolKey)
      ) ?? this.schools[0];

    if (this.school) this.getSchoolReport(this.program.programId);
  }

  getSchoolReport(programId: number): void {
    this.resetUnitTeacherDialog();
    this.spinnerService.show();

    this.schoolReport$ = this.schoolReportsService
      .getSchoolReport(this.school.id, programId)
      .pipe(
        tap((schoolReport: GetSchoolReportResponse) =>
          mixpanel.track('School Report Loaded', {
            school: schoolReport.schoolLevelMetrics.name,
          })
        ),
        finalize(() => this.spinnerService.hide())
      );
  }

  getSchoolTeachersByUnit(
    unit: number,
    testNumber: number,
    index: number
  ): void {
    this.resetUnitTeacherDialog();

    this.unitIndex = index;
    this.teachersByUnit$ = this.schoolReportsService.getTeachersByUnit({
      schoolId: this.school.id,
      programId: this.program.programId,
      unit: unit,
      testNumber: testNumber,
    });
  }

  getTeacherReportByUnit(
    unit: number,
    testNumber: number,
    teacherId: string
  ): void {
    this.selectedTeacherId = teacherId;
    this.teacherUnitReport$ = this.schoolReportsService.getTeacherReportByUnit({
      schoolId: this.school.id,
      programId: this.program.programId,
      unit: unit,
      testNumber: testNumber,
      teacherId: teacherId,
    });
  }

  onLevelChange() {
    this.getSchoolReport(this.program.programId);
    this.mixpanelService.trackLevelChange(this.program.programId);
  }

  goToRoster(classId: string): void {
    void this.router.navigate(['/utt/class', classId]);
  }

  resetTeacherReport(): void {
    this.selectedTeacherId = undefined;
    this.teacherUnitReport$ = undefined;
  }

  resetUnitTeacherDialog(): void {
    this.resetTeacherReport();
    this.teachersByUnit$ = undefined;
  }
}

interface LevelOption {
  label: string;
  programId: number;
}

interface LevelOption {
  label: string;
  programId: number;
}

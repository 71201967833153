<ng-container *ngIf="school">
  <div class="flex justify-content-between align-items-center">
    <h1 data-automationId="school_reports_header">
      <span *ngIf="schools.length > 1">{{ districtName }} Reports</span>
      <span *ngIf="schools.length === 1">{{ school.name }} Report</span>
    </h1>

    <p-dropdown
      (onChange)="getSchoolReport(program.programId)"
      *ngIf="schools.length > 1"
      [(ngModel)]="school"
      [options]="schools"
      optionLabel="name"
      styleClass="w-20rem"
    ></p-dropdown>
  </div>

  <p class="p-text-secondary mb-5">
    <span *ngIf="schools.length > 1">
      View Fundations Level reports for each school within the
      {{ districtName }} district.
    </span>
    <span *ngIf="schools.length === 1">
      View Fundations Level reports for {{ school.name }}.
    </span>
  </p>

  <h3 *ngIf="schools.length > 1">{{ school.name }}</h3>

  <p-tabMenu
    [model]="programs"
    [(activeItem)]="program"
    (activeItemChange)="onLevelChange()"
  ></p-tabMenu>

  <div class="row p-3" *ngIf="schoolReport$ | async; let report">
    <div class="col-8 card mb-0">
      <ng-container *ngIf="!teacherUnitReport$">
        <ng-container
          *ngIf="
            report.schoolLevelMetrics.levelMetrics.units.length;
            else levelMetricsEmpty
          "
        >
          <p class="p-text-secondary ps-3 pt-3">
            Total students above or below benchmark score per Fundations unit.
          </p>
          <p-chart
            (onDataSelect)="
              getSchoolTeachersByUnit(
                report.schoolLevelMetrics.levelMetrics.units[
                  $event.element.index
                ],
                report.schoolLevelMetrics.levelMetrics.testNumbers[
                  $event.element.index
                ],
                $event.element.index
              )
            "
            [data]="report.schoolLevelMetrics.levelMetrics.unitBenchmark"
            [options]="chartOptions"
            height="30vh"
            data-automationId="school_reports_chart_benchmark_score"
            type="bar"
          >
          </p-chart>
        </ng-container>

        <ng-template #levelMetricsEmpty>
          <div
            class="d-flex align-items-center justify-content-center"
            style="height: 30vh"
          >
            <p>There's no data available for this level.</p>
          </div>
        </ng-template>
      </ng-container>

      <ng-container *ngIf="teacherUnitReport$">
        <ng-container
          *ngIf="
            teacherUnitReport$ | async;
            let teacherReport;
            else: teacherUnitReportLoading
          "
        >
          <p class="p-text-secondary ps-3 pt-3">
            Total students above or below benchmark score for this teacher's
            classes.
          </p>

          <p-chart
            (onDataSelect)="
              goToRoster(teacherReport.classIds[$event.element.index])
            "
            [data]="teacherReport"
            [options]="chartOptions"
            height="30vh"
            data-automationId="school_reports_chart_benchmark_score"
            type="bar"
          >
          </p-chart>
        </ng-container>
        <ng-template #teacherUnitReportLoading>
          <div
            class="d-flex align-items-center justify-content-center"
            style="height: 30vh"
          >
            <p>Please wait...</p>
          </div>
        </ng-template>
      </ng-container>
    </div>
    <div class="col-4 py-0">
      <div class="card">
        <p class="p-text-secondary" *ngIf="!teachersByUnit$">
          Click on a bar in the graph to view a detailed report of this school's
          progress per Fundations unit here.
        </p>
        <div *ngIf="teachersByUnit$">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="m-0">
              {{
                report.schoolLevelMetrics.levelMetrics.unitBenchmark.labels[
                  unitIndex
                ]
              }}
            </h5>
            <i
              class="pi pi-times"
              role="button"
              (click)="resetUnitTeacherDialog()"
            ></i>
          </div>

          <p class="p-text-secondary mb-5" *ngIf="!selectedTeacherId">
            View a detailed report for a specific teacher with test results for
            this selection.
          </p>

          <p class="p-text-secondary mb-5" *ngIf="selectedTeacherId">
            Click on the selected teacher again to deselect and view the full
            dataset.
          </p>

          <div
            *ngIf="
              teachersByUnit$ | async;
              let teachers;
              else: teachersByUnitLoading
            "
          >
            <ul class="list-unstyled">
              <li class="mb-3" *ngFor="let teacher of teachers">
                <a
                  class="link-primary"
                  role="button"
                  [class.fw-bold]="selectedTeacherId == teacher.id"
                  (click)="
                    selectedTeacherId == teacher.id
                      ? resetTeacherReport()
                      : getTeacherReportByUnit(
                          report.schoolLevelMetrics.levelMetrics.units[
                            unitIndex
                          ],
                          report.schoolLevelMetrics.levelMetrics.testNumbers[
                            unitIndex
                          ],
                          teacher.id
                        )
                  "
                  >{{ teacher.name }}
                </a>
              </li>
            </ul>
          </div>
          <ng-template #teachersByUnitLoading>
            <p class="p-text-secondary">Please wait...</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="schools && !school">
  <h1 data-automationId="school_reports_header">School Reports</h1>
  <p>No reports exist yet. Please check back later.</p>
</ng-container>
